import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";
import { db } from "../../FB/firebaseConfig"; // Import Firestore configuration
import { collection, getDocs, doc, getDoc } from "firebase/firestore"; // Import Firestore methods
import "./footer.css";

const footerInfoLinks = [
  {
    display: { english: "Privacy Policy", arabic: "سياسة الخصوصية" },
    url: "#",
  },
  {
    display: { english: "Cancellation & Replacement Policy", arabic: "سياسة الإلغاء والاستبدال" },
    url: "#",
  },
  {
    display: { english: "Terms and Conditions (T&C)", arabic: "الشروط والأحكام" },
    url: "#",
  },
  {
    display: { english: "General Website Content Requirements", arabic: "متطلبات محتوى الموقع العامة" },
    url: "#",
  },
];

const Footer = () => {
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({ title: "", english: "", arabic: "" });
  const [supportContacts, setSupportContacts] = useState([]);
  const [getInTouchInfo, setGetInTouchInfo] = useState([]);
  const [language, setLanguage] = useState("english");

  const toggleModal = () => setModal(!modal);

  useEffect(() => {
    const fetchSupportContacts = async () => {
      const supportCollection = collection(db, "supportContacts");
      const supportSnapshot = await getDocs(supportCollection);
      const supportData = supportSnapshot.docs.map(doc => doc.data());
      setSupportContacts(supportData);
    };

    const fetchGetInTouchInfo = async () => {
      const contactCollection = collection(db, "getInTouch");
      const contactSnapshot = await getDocs(contactCollection);
      const contactData = contactSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setGetInTouchInfo(contactData);
    };

    fetchSupportContacts();
    fetchGetInTouchInfo();
  }, []);

  const fetchDocument = async (collectionName, docId) => {
    const docRef = doc(db, collectionName, docId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log("No such document!");
      return null;
    }
  };

  const handleModalOpen = async (type) => {
    let content;
    
    if (type === "privacyPolicy") {
      content = await fetchDocument("privacyPolicy", "k9BcxVtlfnuUhrJGFBxY");
      setModalContent({ title: "Privacy Policy", ...content });
    } else if (type === "termsAndConditions") {
      content = await fetchDocument("termsAndConditions", "xYc2CXJde6E2Oxyq1KvR");
      setModalContent({ title: "Terms and Conditions", ...content });
    } else if (type === "cancellationPolicy") {
      content = await fetchDocument("cancellationPolicy", "rY717yfovmIs9vYhOPO5");
      setModalContent({ title: "Cancellation & Replacement Policy", ...content });
    } else if (type === "contentRequirements") {
      content = await fetchDocument("contentRequirements", "p6tnEsEuwqB9mC3E1AVX");
      setModalContent({ title: "General Website Content Requirements", ...content });
    }

    toggleModal();
  };

  const toggleLanguage = () => {
    setLanguage(prevLanguage => (prevLanguage === "english" ? "arabic" : "english"));
  };

  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="3" md="6" className="mb-4">
            <h2 className="d-flex align-items-center gap-1">Altafawoq</h2>
            <div className="follows">
              <p className="mb-0">Follow us on social media</p>
              <span>
                <a href="https://www.facebook.com/profile.php?id=61566476404242" target="_blank" rel="noopener noreferrer">
                  <i className="ri-facebook-line"></i>
                </a>
              </span>
              <span>
                <a href="https://www.instagram.com/altafawoqa" target="_blank" rel="noopener noreferrer">
                  <i className="ri-instagram-line"></i>
                </a>
              </span>
              <span>
                <a href="https://x.com/AltafawoqA" target="_blank" rel="noopener noreferrer">
                  <i className="ri-twitter-line"></i>
                </a>
              </span>
            </div>
          </Col>

          <Col lg="3" md="6">
            <h6 className="fw-bold">Support</h6>
            {supportContacts.map((contact, index) => (
              <div key={index}>
                <p>Name: {contact.name}</p>
                <p>Email: {contact.email}</p>
              </div>
            ))}
          </Col>

          <Col lg="3" md="6" className="mb-4">
            <h6 className="fw-bold">Information</h6>
            <ListGroup className="link__list">
              {footerInfoLinks.map((item, index) => (
                <ListGroupItem key={index} className="border-0 ps-0 link__item">
                  <a 
                    href={item.url} 
                    onClick={() => {
                      if (item.display.english === "Privacy Policy") handleModalOpen("privacyPolicy");
                      else if (item.display.english === "Terms and Conditions (T&C)") handleModalOpen("termsAndConditions");
                      else if (item.display.english === "Cancellation & Replacement Policy") handleModalOpen("cancellationPolicy");
                      else if (item.display.english === "General Website Content Requirements") handleModalOpen("contentRequirements");
                    }}
                  >
                    {item.display[language]}
                  </a>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>

          <Col lg="3" md="6">
            <h6 className="fw-bold">Get in Touch</h6>
            {getInTouchInfo.map((info) => (
              <div key={info.id}>
                <p>Address: {info.address}</p>
                <p>Phone: {info.phone}</p>
                <p>Email: {info.email}</p>
              </div>
            ))}
          </Col>
        </Row>
        <Button onClick={toggleLanguage} className="mt-3">
          {language === "english" ? "Translate to Arabic" : "ترجمة إلى الإنجليزية"}
        </Button>
      </Container>

      {/* Modal for Privacy Policy, Terms & Conditions, Cancellation Policy, and Content Requirements */}
      <Modal isOpen={modal} toggle={toggleModal} centered size="lg">
        <ModalHeader toggle={toggleModal}>{modalContent.title}</ModalHeader>
        <ModalBody>
          <div style={{ whiteSpace: 'pre-wrap', lineHeight: '1.6' }}>
            <h5>{language === "english" ? "English Version" : "النسخة العربية"}</h5>
            <p>{modalContent[language]}</p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>Close</Button>
        </ModalFooter>
      </Modal>
    </footer>
  );
};

export default Footer;