import React, { useState } from "react";
import { Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Slider from "react-slick"; // تأكد من تثبيت react-slick وslick-carousel
import "./choose.css";

// استيراد صور الدورات
import englishBeginnersImg from "../../images/R.jpeg"; // استبدل بمسار الصورة الفعلي
import businessEnglishImg from "../../images/branding.png"; // استبدل بمسار الصورة الفعلي
import ieltsPrepImg from "../../images/ielts.png"; // استبدل بمسار الصورة الفعلي
import { useLanguage } from "../header/LanguageContext"; // تعديل المسار حسب الحاجة

const ChooseUs = () => {
  const [modal, setModal] = useState(false);
  const [courseTitle, setCourseTitle] = useState("");
  const { language } = useLanguage(); // الوصول إلى اللغة الحالية

  const toggleModal = () => setModal(!modal);

  const handleCourseClick = (title) => {
    setCourseTitle(title);
    toggleModal();
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true,
  };

  const courses = [
    {
      title: {
        en: "PYD English program",
        ar: "برنامج الإنجليزية PYD",
      },
      description: {
        en: "Focuses on the four language skills (reading, listening, speaking, and writing).",
        ar: "يركز على أربع مهارات لغوية (القراءة، الاستماع، التحدث، والكتابة).",
      },
      location: "Online",
      image: englishBeginnersImg,
    },
    {
      title: {
        en: "STEP Preparation",
        ar: "إعداد STEP",
      },
      description: {
        en: "With STEP, you’ll find yourself interacting confidently. STEP determines your current level and helps you improve your English.",
        ar: "مع STEP، ستجد نفسك تتفاعل بثقة. يحدد STEP مستواك الحالي ويساعدك على تحسين لغتك الإنجليزية.",
      },
      location: "Online",
      image: businessEnglishImg,
    },
    {
      title: {
        en: "IELTS Preparation",
        ar: "إعداد IELTS",
      },
      description: {
        en: "Comprehensive training to help you excel in the IELTS exam.",
        ar: "تدريب شامل لمساعدتك على التفوق في اختبار IELTS.",
      },
      location: "Online",
      image: ieltsPrepImg,
    },
  ];

  return (
    <section>
      <Container>
        <Row>
          <Col lg="6">
            <div className="choose__content">
              <h2>{language === "ar" ? "لماذا تختارنا" : "Why Choose Us"}</h2>
              <p>
                {language === "ar" 
                  ? "إذا كنت تبحث عن التميز. سنعلمك اللغة الإنجليزية باستخدام أحدث وأفضل تقنيات التعليم. سواء وجهًا لوجه أو عبر الإنترنت، سنوفر لك أفضل الطرق لاكتساب اللغة ومساعدتك على التعلم بسهولة وسلاسة. في أكاديمية التفوق، نعمل تحت شعار (الممارسة تجعل الكمال). نتيجة لذلك، ستحصل على رعاية intensive ومتابعة مما سيمكنك من تعلم اللغة الإنجليزية بسرعة وبشكل مثالي. سيقوم معلمونا الممتازون والمتخصصون، الذين اخترناهم بعناية، بتعليمك بطريقة سهلة وفعالة للغاية."
                  : "If you are looking for excellence. We will teach you the English language using the most advanced and up-to-date teaching techniques. Whether face-to-face or online, we will provide you with the best ways to acquire the language and help you learn easily and smoothly. In Altafawq Academy, we work under a slogan (Practice makes perfect). As a result, you will receive intensive care and follow-up which will make you learn English quickly and perfectly. Our excellent and specialized teachers whom we've selected very carefully will teach you in a very easy and effective way."}
              </p>
            </div>
          </Col>
          <Col lg="6">
            <div className="choose__courses-card">
              <h2 className="courses-title">{language === "ar" ? "دوراتنا الشائعة" : "Our Popular Courses"}</h2>
              <Slider {...settings}>
                {courses.map((course, index) => (
                  <div key={index} className="single__course" onClick={() => handleCourseClick(course.title[language])}>
                    <img src={course.image} alt={course.title[language]} className="course-image" />
                    <h6 className="mb-3 fw-bold">{course.title[language]}</h6>
                    <p>{course.description[language]}</p>
                    <div className="course__info mt-4">
                      <p>Location: {course.location}</p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Modal for Sign In Prompt */}
      <Modal isOpen={modal} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>{language === "ar" ? "يتطلب تسجيل الدخول" : "Sign In Required"}</ModalHeader>
        <ModalBody>
          {language === "ar" 
            ? `يرجى إنشاء حساب أولاً لعرض تفاصيل دورة ${courseTitle}.`
            : `Please create account first to view details for ${courseTitle}.`}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>Close</Button>
          {/* يمكنك إضافة رابط إلى صفحة تسجيل الدخول هنا */}
        </ModalFooter>
      </Modal>
    </section>
  );
};

export default ChooseUs;