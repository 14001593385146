import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./hero-section.css";
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { db } from "../../user/firebaseConfig";
import { collection, addDoc } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { useLanguage } from "../header/LanguageContext"; // Import the context
import logo from '../../images/WhatsApp_Image_2024-09-19_at_20.08.01_2e4e8613-removebg-preview.png'; // Updated logo path

const HeroSection = () => {
  const { language } = useLanguage(); // Access the current language
  const [modal, setModal] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
  });
  const navigate = useNavigate();

  const toggleModal = () => setModal(!modal);
  const handleToggle = () => setIsLogin(!isLogin);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    if (isLogin) {
      try {
        const userCredential = await signInWithEmailAndPassword(auth, formData.email, formData.password);
        navigate("/profile");
        toggleModal();
      } catch (error) {
        alert("Error logging in. Please check your credentials.");
      }
    } else {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
        await addDoc(collection(db, "users"), {
          uid: userCredential.user.uid,
          firstName: formData.firstName,
          lastName: formData.lastName,
          phone: formData.phone,
          email: formData.email,
        });
        alert("User signed up successfully!");
        navigate("/profile");
        toggleModal();
        setFormData({ firstName: "", lastName: "", email: "", password: "", phone: "" });
      } catch (error) {
        console.error("Error signing up: ", error);
        alert("Error signing up. Please try again.");
      }
    }
  };

  const translations = {
    title: language === "ar" ? "أكاديمية التفوق" : "Altafawoq Academy",
    description: language === "ar" ? 
      `إلى كل من يطمح في التفوق في اللغة الإنجليزية، نحن هنا في أكاديمية التفوق لتحقيق أهدافكم وتحويل أحلامكم إلى واقع. مع التميز والإتقان والخبرة، سنعمل معًا لتحقيق أعلى الدرجات في اختبار STEP، واختبار IELTS، وPYD. نقدم دورات ممتازة (أساسية، متوسطة، متقدمة) وجهًا لوجه في أكاديميتنا أو عبر الإنترنت على منصتنا. كما نقدم دورات معتمدة في اللغة الإنجليزية لكل من يرغب في تعلم أو تحسين مهاراته في اللغة الإنجليزية (القراءة، التحدث، الاستماع، الكتابة) من المستويات المبتدئة إلى المتقدمة. يتم تسهيل ذلك من قبل معلمين معتمدين باستخدام تقنيات حديثة، سواء وجهًا لوجه في أكاديميتنا أو عبر الإنترنت.` :
      `To all those who aspire to excel in the English Language, we are here at Altafawoq Academy to achieve your goals and turn your dreams into reality. With excellence, mastery, and experience, we will work together to achieve the highest grades at STEP Test, IELTS Test, and PYD. We offer excellent courses (Basic, Intermediate, Advanced) face-to-face at our Academy or online on our platform.   for everyone who wants to learn or improve their English skills (Reading, Speaking, Listening, Writing) from beginner to advanced levels. This is facilitated by certified  teachers using modern techniques, whether face-to-face at our academy or online.`,
    modalHeader: isLogin ? (language === "ar" ? "تسجيل الدخول" : "Login") : (language === "ar" ? "إنشاء حساب" : "Create Account"),
    firstNameLabel: language === "ar" ? "الاسم الأول" : "First Name",
    lastNameLabel: language === "ar" ? "اسم العائلة" : "Last Name",
    phoneLabel: language === "ar" ? "رقم الهاتف (+XX)" : "Phone Number (+XX)",
    emailLabel: language === "ar" ? "البريد الإلكتروني" : "Email",
    passwordLabel: language === "ar" ? "كلمة المرور" : "Password",
    confirmPasswordLabel: language === "ar" ? "تأكيد كلمة المرور" : "Confirm Password",
    loginButton: language === "ar" ? "تسجيل الدخول" : "Login",
    createAccountButton: language === "ar" ? "تسجيل دخول/إنشاء حساب" : " Log in/Create Account",
    switchToLogin: language === "ar" ? "الانتقال إلى تسجيل الدخول" : "Switch to Login",
    cancelButton: language === "ar" ? "إلغاء" : "Cancel",
  };

  return (
    <section className="hero-section">
      <Container>
        <Row className="align-items-center">
          <Col lg="6" md="6">
            <div className="hero__content">
              <h2 className="mb-4 animated fadeIn">{translations.title}</h2>
              <p className="mb-4 animated fadeIn">{translations.description}</p>
              <Button color="primary" onClick={toggleModal}>{translations.createAccountButton}</Button>
              <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>{translations.modalHeader}</ModalHeader>
                <ModalBody>
                  <form onSubmit={handleSubmit}>
                    {!isLogin && (
                      <>
                        <div className="form-group">
                          <label>{translations.firstNameLabel}</label>
                          <input 
                            type="text" 
                            name="firstName" 
                            className="form-control" 
                            placeholder={translations.firstNameLabel} 
                            value={formData.firstName} 
                            onChange={handleChange} 
                            required 
                          />
                        </div>
                        <div className="form-group">
                          <label>{translations.lastNameLabel}</label>
                          <input 
                            type="text" 
                            name="lastName" 
                            className="form-control" 
                            placeholder={translations.lastNameLabel} 
                            value={formData.lastName} 
                            onChange={handleChange} 
                            required 
                          />
                        </div>
                        <div className="form-group">
                          <label>{translations.phoneLabel}</label>
                          <input 
                            type="tel" 
                            name="phone" 
                            className="form-control" 
                            placeholder={translations.phoneLabel} 
                            value={formData.phone} 
                            onChange={handleChange} 
                            required 
                          />
                        </div>
                      </>
                    )}
                    <div className="form-group">
                      <label>{translations.emailLabel}</label>
                      <input 
                        type="email" 
                        name="email" 
                        className="form-control" 
                        placeholder={translations.emailLabel} 
                        value={formData.email} 
                        onChange={handleChange} 
                        required 
                      />
                    </div>
                    <div className="form-group">
                      <label>{translations.passwordLabel}</label>
                      <input 
                        type="password" 
                        name="password" 
                        className="form-control" 
                        placeholder={translations.passwordLabel} 
                        value={formData.password} 
                        onChange={handleChange} 
                        required 
                      />
                    </div>
                    {!isLogin && (
                      <div className="form-group">
                        <label>{translations.confirmPasswordLabel}</label>
                        <input 
                          type="password" 
                          name="confirmPassword" 
                          className="form-control" 
                          placeholder={translations.confirmPasswordLabel} 
                          required 
                        />
                      </div>
                    )}
                    <Button color="primary" type="submit">{isLogin ? translations.loginButton : translations.createAccountButton}</Button>
                  </form>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={handleToggle}>
                    {isLogin ? translations.createAccountButton : translations.switchToLogin}
                  </Button>
                  <Button color="secondary" onClick={toggleModal}>{translations.cancelButton}</Button>
                </ModalFooter>
              </Modal>
            </div>
          </Col>
          <Col lg="6" md="6" className="text-center">
            <img src={logo} alt="Logo" className="hero-logo animated fadeIn" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;